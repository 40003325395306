.mySpreadsheet {
    width: 100%;
}

.mySpreadsheet .Spreadsheet__table,
.mySpreadsheet .Spreadsheet__table .Spreadsheet__row,
.mySpreadsheet .Spreadsheet__table .Spreadsheet__cell {
    background-color: transparent !important;
}

.mySpreadsheet .Spreadsheet__header {
    background-color: transparent !important;
    color:#707EAE;
    font-weight: 700;
    border: none;
    font-size: small;
    padding-bottom: 10px;
}

.Spreadsheet {
    background-color: transparent !important;
}
