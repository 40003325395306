@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url('/node_modules/react-grid-layout/css/styles.css');
@import url('/node_modules/react-resizable/css/styles.css');

body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}
.firebase-emulator-warning {
  display: none;
}

input#file-upload-button {
  background-color: #422AFB; /* Brand color */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.react-grid-item>.react-resizable-handle::after {
  border-right: 0;
  border-bottom: 0;
}

.react-resizable-handle {
  background-image: none;
}

.react-draggable-transparent-selection .react-draggable-dragging {
  box-shadow: 0px 20px 50px rgba(112, 144, 176, 0.25);
  border-radius: 10px;
}

.react-grid-item.react-grid-placeholder {
  background-color: #a9a9a9;
  border-radius: 10px;
}

.myAccordion .chakra-accordion__item {
  border-top: none !important;
  border-bottom: none !important;
}

.ql-container.ql-snow {
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  min-height: 115px;
}

.ql-toolbar.ql-snow {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}